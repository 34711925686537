import React, { useState } from 'react';
import ReactGa from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { checkoutText } from '../DataFiles/standardText';
import { routerMap } from '../utils/router';
import { alphaMap } from '../utils/trackingUtils';

import '../MainForm/MainForm.css';
import PhotoIdForm from './PhotoIdForm';
import DemographicForm from './DemographicForm';
import LoadingModal from './LoadingModal';
import TurnstileWidget from '../Turnstile/TurnstileWidget';
const CheckoutFormContainer = (props) => {
  const { formData, onPageUpdate, currentScreen } = props;
  const [turnstileToken, setTurnstileToken] = useState('');
  const handleTurnstileToken = (token) => {
    setTurnstileToken(token);
  };
  const [localState, setLocalState] = useState({
    filename: '',
    photo: '',
    fullSrc: '',
  });
  const [error, setError] = useState('');
  const [isPendingData, setIsPendingData] = useState(false);
  // const [token, setToken] = useState(null);
  useState(false);
  const [validated, setValidated] = useState(false);
  const [demographicData, setDemographicData] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });
  const [cardInfo, setCardInfo] = useState({
    cardNum: '',
    expMonth: '',
    expYear: '',
    cvc: '',
  });

  const {
    submitPhoto,
    createVisit,
    // validateTurnstile
  } = routerMap;

  const navigate = useNavigate();

  const handleBeforeUnload = (e) => {
    e.preventDefault();
    e.returnValue = '';
    return 'Are you sure you want to leave this page, your information is still submitting?';
  };

  const handleCreateVisit = async () => {
    try {
      window.addEventListener('beforeunload', handleBeforeUnload);
      setIsPendingData(true);
      const visitId = await createVisit(
        cardInfo,
        formData,
        demographicData,
        setError,
        'Stripe',
          turnstileToken
      );
      ReactGa.event('customEvent', {
        category: 'paymentProcessed',
        action: 'payment',
        label: `${alphaMap['consultSubmitted']} - consultSubmitted`,
      });

      if (visitId) {
        await submitPhoto(visitId, localState, setError, navigate);
      }
    } catch (error) {
      console.error('handleCreateVisit error', error);
    }
    setIsPendingData(false);
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };

  // const handleTurnstile = async () => {
  //   const data = {
  //     token,
  //   };
  //   await validateTurnstile(data);
  // };

  // const buttonContainerRef = useRef(); // Creating ref element to assign as a form element attribute

  // useEffect(() => {
  //   scriptLoader();
  //   return () => {
  //     scriptUnloader(); // Remove the script element on unmount
  //   };
  // }, []);

  // const scriptLoader = () => {
  //   const script = document.createElement('script');
  //   script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
  //   script.id = 'checkout-button';
  //   script.async = true;
  //   script.defer = true;
  //   document.head.appendChild(script);
  //   window.addEventListener('load', scriptMover); // Runs when all the assets loaded completely
  // };

  // const scriptUnloader = () => {
  //   document.body.removeChild(document.querySelector('#checkout-button'));
  // };

  // const scriptMover = () => {
  //   const button = document.querySelector('.mercadopago-button'); // Gets the button
  //   buttonContainerRef.current.appendChild(button); // Appends the button to the ref element, in this case form element
  // };

  const onPhotoReady = (photoState) => {
    setLocalState({
      ...photoState,
    });
  };

  const updatePage = () => {
    onPageUpdate('submit');
  };

  const isPhotoScreen = () => {
    return currentScreen === 'photo';
  };

  const isSubmitScreen = () => {
    return currentScreen === 'submit';
  };

  return (
    <>
      <div className='messageBody'>{checkoutText.body}</div>
      {isPhotoScreen() && (
        <PhotoIdForm photoState={localState} onPhotoReady={onPhotoReady} />
      )}
      {isSubmitScreen() && (
        <DemographicForm
          formData={formData}
          setValidated={setValidated}
          onFormValidated={(data, cardData) => {
            setDemographicData(data);
            setCardInfo(cardData);
          }}
        />
      )}

      {error && <div className='checkoutError' style={{margin: '0 auto 0 0'}}>{error}</div>}


      <div className='ccContainer'>
        <TurnstileWidget onTokenChange={handleTurnstileToken} />
        {isSubmitScreen() ? (
          <button
            onClick={handleCreateVisit}
            className='orderButton checkoutButton'
            disabled={!localState.photo.length || !validated || isPendingData}
          >
            Submit your information
          </button>
        ) : (
          <button
            onClick={updatePage}
            className='orderButton checkoutButton'
            disabled={!localState.photo.length}
          >
            Continue
          </button>
        )}
      </div>
      <LoadingModal show={isPendingData} />
    </>
  );
};

export default CheckoutFormContainer;
